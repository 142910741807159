<!-- eslint-disable unused-imports/no-unused-imports -->

<template>
  <b-modal
    id="animal-condition-chart"
    :title="$t('animalWeightAndConditionChart')"
    centered
    size="xl"
    :ok-title="$t('close')"
    ok-only
  >
    <b-row class="mb-2">
      <b-col cols="12" md="6">
        <h2 id="animal-condition-chart-animal-ear">{{ animal.earingno }}</h2>
      </b-col>
      <b-col class="d-flex flex-row-reverse" cols="12" md="6">
        <b-button
          variant="success"
          class="d-flex align-items-center"
          @click="printChart"
        >
          <feather-icon icon="PrinterIcon" size="22" />
          <span class="align-middle ml-2">{{ $t("print") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <div style="position: relative">
      <line-chart
        :key="height"
        class="print"
        :height="height"
        :data="data"
        :options="options"
      />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BCol, BRow, BButton } from "bootstrap-vue";
import LineChart from "@/views/components/LineChart.vue";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    BModal,
    LineChart,
    BCol,
    BRow,
    BButton,
  },
  props: {
    animal: {
      type: Object,
      required: true,
    },
    // { "weight": 33, "condition": 2, "recordeddate": "2023-06-06T16:20:19" }
    conditions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dayjs,
      data: {
        labels: [],
        datasets: [
          {
            label: this.$t("weight"),
            yAxisID: "y",
            backgroundColor: this.transparentize("#4c51bf", 0.92),
            borderColor: "#4c51bf",
          },
          {
            label: this.$t("condition"),
            yAxisID: "y1",
            backgroundColor: this.transparentize("#f56565", 0.92),
            borderColor: "#f56565",
          },
          {
            label: this.$t("averageWeight"),
            yAxisID: "y",
            backgroundColor: this.transparentize("#38a169", 1),
            borderColor: "#38a169",
            borderDash: [15, 15],
            borderDashOffset: 10,
            data: [120, 150, 182, 198, 224, 300, 356],
            hidden: true,
          },
          {
            label: this.$t("averageCondition"),
            yAxisID: "y1",
            backgroundColor: this.transparentize("#ed8936", 1),
            borderColor: "#ed8936",
            borderDash: [15, 15],
            borderDashOffset: 10,
            data: [4, 4.5, 5, 5, 4.5, 4, 5],
            hidden: true,
          },
        ],
      },

      options: {
        responsive: true,
        aspectRatio: 1,

        interaction: {
          mode: "index",
          intersect: true,
        },
        stacked: true,
        plugins: {
          title: {
            display: true,
            text: this.$t("animalWeightAndConditionChart"),
          },
        },
        scales: {
          yAxes: [
            {
              type: "linear",
              display: true,
              position: "right",
              id: "y",
              text: this.$t("weight"),
              scaleLabel: {
                display: true,
                labelString: this.$t("weight"),
              },
            },
            {
              type: "linear",
              display: true,
              position: "left",
              id: "y1",
              scaleLabel: {
                display: true,
                labelString: this.$t("condition"),
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    height() {
      return this.$store.state.app.windowWidth >= 1480
        ? 100
        : this.$store.state.app.windowWidth >= 1200
        ? 150
        : this.$store.state.app.windowWidth >= 768
        ? 250
        : this.$store.state.app.windowWidth >= 375
        ? 350
        : 450;
    },
  },

  watch: {
    conditions: {
      handler() {
        this.setData();
      },
      deep: true,
    },
  },
  mounted() {
    this.setData();
  },
  methods: {
    printChart() {
      var headerDiv = document.querySelector(
        "#animal-condition-chart-animal-ear"
      );

      var canvas = document.querySelector(".print canvas");
      var img = canvas.toDataURL("image/png");
      var win = window.open();
      win.document.write(headerDiv.outerHTML);
      win.document.write(
        '<img src="' + img + '" onload="window.print();window.close()" />'
      );
      window.document.close();
      win.print();
      win.location.reload();
    },
    transparentize(value, opacity) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return (
        "rgba(" +
        parseInt(value.slice(-6, -4), 16) +
        "," +
        parseInt(value.slice(-4, -2), 16) +
        "," +
        parseInt(value.slice(-2), 16) +
        "," +
        alpha +
        ")"
      );
    },
    setData() {
      if (this.conditions.length === 0) {
        this.data.datasets[0].data = [];
        this.data.datasets[1].data = [];
        this.data.labels = [];
        return;
      }

      this.data.datasets[0].data = this.conditions
        .map((c) => c.weight)
        .reverse();

      this.data.datasets[1].data = this.conditions.map((c) => c.condition);
      this.data.labels = this.conditions
        .map((c) => dayjs(c.recordeddate).format("DD.MM.YYYY"))
        .reverse();
    },
  },
};
</script>
