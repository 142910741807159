import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/tr";
import "dayjs/locale/ru";
import "dayjs/locale/az";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refConditionListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "recordeddate",
      label: VueI18n.t("recordedDate"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },
    { key: "weight", label: VueI18n.t("weight"), sortable: true },
    {
      key: "condition",
      label: VueI18n.t("condition"),
      sortable: false,
    },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allConditions = ref([]);
  const filteredConditions = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredConditions.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allConditions.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredConditions.value = allConditions.value.filter((x) =>
        x.conditionname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredConditions.value = allConditions.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchConditions = async (ctx) => {
    var condition = await store
      .dispatch("animalsModule/fetchAnimalCondition", {
        animalid: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("conditions") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allConditions.value = condition;
    filteredConditions.value = [...condition];
    refetchData();

    return condition;
  };

  const deleteCondition = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalCondition", ctx.conditionid.id)
      .then((response) => {
        fetchConditions(ctx.animalid);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("conditions") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("conditions") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchConditions,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refConditionListTable,
    deleteCondition,
    filteredConditions,
    allConditions,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
