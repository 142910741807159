<template>
  <div>
    <b-modal
      id="edit-condition-sidebar"
      centered
      :visible="isEditConditionSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalCondition')"
      @ok="onSubmit"
      @hidden="formValidation(resetblankcondition).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankcondition).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <!-- recordeddate -->
          <validation-provider
            #default="validationContext"
            name="recordeddate"
            rules="required"
          >
            <b-form-group :label="$t('recordedDate')" label-for="recordeddate">
              <b-form-datepicker
                v-model="blankConditionData.recordeddate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="econdition"
            rules="required"
          >
            <b-form-group :label="$t('condition')" label-for="condition">
              <b-form-input
                id="econdition"
                v-model="blankConditionData.condition"
                autofocus
                :state="
                  formValidation(resetblankcondition).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder="earigno"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- weight -->
          <validation-provider #default="validationContext" name="eweight">
            <b-form-group :label="$t('weight')" label-for="eweight">
              <b-form-input
                id="eweight"
                v-model="blankConditionData.weight"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankcondition).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BFormDatepicker,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditConditionSidebarActive",
    event: "update:is-edit-condition-sidebar-active",
  },
  props: {
    isEditConditionSidebarActive: {
      type: Boolean,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankConditionData: { ...this.condition },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      conditiontypeOptions: [],
      selectedConditionType: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  async created() {},
  methods: {
    async changed(val) {
      this.resetblankcondition();
      this.$emit("update:is-edit-condition-sidebar-active", val);
    },
    onSubmit() {
      store
        .dispatch(
          "animalsModule/updateAnimalCondition",
          this.blankConditionData
        )
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-condition-sidebar-active", false);
        });
    },
    async resetblankcondition() {
      this.blankConditionData = {
        ...this.condition,
        conditionid: this.condition.id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-condition-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
