<template>
  <div>
    <condition-list-add-new
      :is-add-new-condition-sidebar-active.sync="isAddNewConditionSidebarActive"
      :selected-animal="selectedAnimal"
      @refetch-data="fetchConditions(selectedAnimal.id)"
    />
    <condition-list-edit
      :key="JSON.stringify(selectedCondition)"
      :is-edit-condition-sidebar-active.sync="isEditConditionSidebarActive"
      :condition.sync="selectedCondition"
      @refetch-data="fetchConditions(selectedAnimal.id)"
    />
    <animal-condition-chart
      :conditions="allConditions"
      :animal="selectedAnimal"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.animal-condition-chart
                variant="success"
                class="d-flex justify-content-between align-items-center"
              >
                <feather-icon icon="BarChart2Icon" size="16" stroke-width="3" />
                <span class="text-nowrap">{{ $t("chart") }}</span>
              </b-button>
              <b-button
                class="ml-2"
                variant="primary"
                @click="
                  isAddNewConditionSidebarActive =
                    !isAddNewConditionSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("conditionAdd") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refConditionListTable"
        class="position-relative"
        :items="filteredConditions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <!-- Column: Condition -->
        <template #cell(conditionname)="data">
          <b-link @click="openEditSideBar(data)">
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.conditionname }}
            </span>
          </b-link>
        </template>

        <template #cell(delete)="data">
          <b-link
            @click="
              deleteCondition({
                animalid: selectedAnimal.id,
                conditionid: data.item,
              })
            "
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredConditions.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useConditionList from "./useConditionList";
import AnimalConditionChart from "@/views/components/AnimalConditionChart.vue";
import ConditionListAddNew from "./AnimalListAddConditions.vue";
import ConditionListEdit from "./AnimalListEditConditions.vue";
import VueI18n from "@/libs/i18n";
import vSelect from "vue-select";
export default {
  components: {
    ConditionListAddNew,
    ConditionListEdit,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
    AnimalConditionChart,
  },
  props: {
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  mounted() {},

  beforeRouteEnter(to, from, next) {
    if (to.name == "conditions") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("conditions"),
        active: true,
      });
    }
    next();
  },
  setup(props, { emit }) {
    const isAddNewConditionSidebarActive = ref(false);
    const isEditConditionSidebarActive = ref(false);
    const selectedCondition = ref({});
    const openEditSideBar = ({ item }) => {
      selectedCondition.value = item;
      isEditConditionSidebarActive.value = !isEditConditionSidebarActive.value;
    };
    const conditions = ref([]);
    const {
      fetchConditions,
      tableColumns,
      perPage,
      currentPage,
      totalConditions,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConditionListTable,
      refetchData,
      allConditions,
      filteredConditions,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteCondition,
    } = useConditionList();

    fetchConditions(props.selectedAnimal.id);

    return {
      // Sidebar
      isAddNewConditionSidebarActive,
      isEditConditionSidebarActive,
      openEditSideBar,
      conditions,
      selectedCondition,

      fetchConditions,
      tableColumns,
      perPage,
      currentPage,
      totalConditions,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConditionListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      allConditions,
      filteredConditions,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteCondition,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
