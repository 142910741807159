<template>
  <div>
    <b-modal
      id="add-new-condition-sidebar"
      centered
      :visible="isAddNewConditionSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('addAnimalWeightAndCondition')"
      @ok="$refs.submitButtonRef.click()"
      @hidden="resetForm"
      @change="
        (val) => $emit('update:is-add-new-condition-sidebar-active', val)
      "
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- recordeddate -->
          <validation-provider
            #default="validationContext"
            name="recordeddate"
            rules="required"
          >
            <b-form-group :label="$t('recordedDate')" label-for="recordeddate">
              <b-form-datepicker
                v-model="blankMilk.recordeddate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="condition"
            rules="required"
          >
            <b-form-group :label="$t('condition')" label-for="condition">
              <b-form-input
                id="condition"
                v-model="blankMilk.condition"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="earigno"
                type="number"
                step="0.1"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- weight -->
          <validation-provider #default="validationContext" name="weight">
            <b-form-group :label="$t('weight')" label-for="weight">
              <b-form-input
                id="weight"
                v-model="blankMilk.weight"
                autofocus
                type="number"
                step="0.1"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewConditionSidebarActive",
    event: "update:is-add-new-condition-sidebar-active",
  },
  props: {
    isAddNewConditionSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const blankMilkData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      recordeddate: dayJs().format(),
      condition: 0,
      weight: 0,
    };

    const blankMilk = ref(JSON.parse(JSON.stringify(blankMilkData)));
    const resetblankMilk = () => {
      blankMilk.value = JSON.parse(JSON.stringify(blankMilkData));
    };

    const onSubmit = () => {
      store
        .dispatch("animalsModule/addAnimalCondition", blankMilk.value)
        .then((res) => {
          if (res.statusdata == "-2") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "alert",
              },
            });
          } else if (res.statusdata == "1") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-add-new-condition-sidebar-active", false);
          } else {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalConditionNotAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankMilk);
    return {
      blankMilk,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-condition-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
